import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/Container"
import {SectionTitle} from "../components/SectionTitle/styles"
import ConsistentIcon from "../images/consistent.svg"
import RecognizableIcon from "../images/recognizable.svg"
import FocusedIcon from "../images/focused.svg"
import CirclesFour from "../images/circlesfour.svg"
import GuidelinesIcon from "../images/guidelines.svg"
import PrinterIcon from "../images/printer.svg"
import DigitalContentIcon from "../images/digitalcontent.svg"
import {ButtonGf, ButtonLink, ButtonWrapper, PopupText} from "../components/Button/styles";
import { colors } from "../const/colors"
import {IoCheckmark} from "react-icons/io5"
import {InfoBox} from "../components/Footer/styles";
import {BannerSection, BenefitsSection} from "../components/WebsitePage/styles";
import CircleTwo from "../images/greyCirclesLeft.svg";
import CircleThree from "../images/grayCirclesRight.svg";
import { BannerStyles} from "../const/inline_styles";
import { OurServicesSection } from "../components/PackagesPage/styles";
import {ServiceBox, ServiceBoxWrapper} from "../components/PackagesPage/styles";

import {SupportSection} from "../components/ApplicationPage/styles";
import Img from "gatsby-image"
import localData from "../localization/branding.json"

// const flags = {
//     "en": localData.locales[0].value,
//     "es": localData.locales[1].value
// }

const flags = [
    {
        name: "en",
        uri: localData.locales[0].value
    },
    {
        name: "es",
        uri : localData.locales[1].value
    }
]

const GfBranding = ({location, pageContext, data}) => {
    const lang = pageContext.lang

    const localizedImages = {
        "en": data.bprocessEn.childImageSharp.fluid,
        "es": data.bprocessEs.childImageSharp.fluid,
    }
    const meta = data?.template?.locales.find(l => l.iso === lang)
    const metaStatic = localData.locales.find(l => l.iso === lang)
    return <Layout location={location} lang={lang} flags={flags}>
        <SEO
            title={ meta?.metaTitle || metaStatic.metaTitle}
            description={ meta?.metaDescription || metaStatic.metaDescription}
            keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
        />
        <BannerSection>
            <Container>
                <h2 style={BannerStyles.h2}>{localData.pageTitle[lang]}</h2>
                <SectionTitle>
                    {localData.sections.hero.title[lang]}
                </SectionTitle>
                <p style={{maxWidth:"900px"}}>{localData.sections.hero.description[lang]}</p>
                <div className="info-box-list">
                    <InfoBox space="7px" step={1} icon={<img src={ConsistentIcon} alt="consistent" />} text={localData.sections.hero.group.first[lang]} color={colors.grayColor} />
                    <InfoBox space="7px" step={2} size="34px" icon={<img src={RecognizableIcon} alt="recognizable" />} text={localData.sections.hero.group.second[lang]} color={colors.grayColor} />
                    <InfoBox space="7px" step={3} icon={<img src={FocusedIcon} alt="focused" />} text={localData.sections.hero.group.third[lang]} color={colors.grayColor} />
                </div>
                <div className="free-consultation">
                    <PopupText text={localData.sections.hero.button.label[lang]} url="https://calendly.com/tina-greenflamingo/30min" />
                </div>
            </Container>
            <div className="bg-image">
                <img src={CirclesFour} alt="circles"/>
            </div>
        </BannerSection>
        <BenefitsSection>
            <Container>
                <SectionTitle align="center">{localData.sections.benefits.title[lang]}</SectionTitle>
                <div className="content">
                    <p style={{textAlign:"center"}}>{localData.sections.benefits.description[lang]}</p>

                    <ul>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{ __html: localData.sections.benefits.group.first[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{ __html: localData.sections.benefits.group.second[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{ __html: localData.sections.benefits.group.third[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{ __html: localData.sections.benefits.group.fourth[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{ __html: localData.sections.benefits.group.fifth[lang]}} /></li>
                        <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{ __html: localData.sections.benefits.group.sixth[lang]}} /></li>
                    </ul>
                    <div className="get-in-touch">
                        <h4>{localData.sections.benefits.subTitle[lang]}</h4>
                        <span>{localData.sections.benefits.subDescription[lang]}</span>
                        {/*<ButtonGf>Start</ButtonGf>*/}
                        <div className="free-consultation free-consultation--flex">
                            <PopupText url="https://calendly.com/tina-greenflamingo/30min" text={localData.sections.benefits.button.label[lang]} />
                        </div>
                    </div>
                </div>
            </Container>
            <img className="left" src={CircleTwo} alt="circles two"/>
            <img className="right" src={CircleThree} alt="circles three"/>
        </BenefitsSection>
        <OurServicesSection>
            <Container>
                <SectionTitle align="center">{localData.sections.philosophy.title[lang]}</SectionTitle>
                <ServiceBoxWrapper>
                    <ServiceBox iconBottomSpace="1.7rem" iconWidth="50px" icon={GuidelinesIcon} title={localData.sections.philosophy.group.first.title[lang]} subtitle={localData.sections.philosophy.group.first.description[lang]}>
                            <span>{localData.sections.philosophy.group.first.variations.first[lang]}</span>
                            <span>{localData.sections.philosophy.group.first.variations.second[lang]}</span>
                            <span>{localData.sections.philosophy.group.first.variations.third[lang]}</span>
                            <span>{localData.sections.philosophy.group.first.variations.fourth[lang]}</span>
                            <span>{localData.sections.philosophy.group.first.variations.fifth[lang]}</span>
                            <span>{localData.sections.philosophy.group.first.variations.sixth[lang]}</span>
                    </ServiceBox>
                    <ServiceBox icon={PrinterIcon} title={localData.sections.philosophy.group.second.title[lang]} subtitle={localData.sections.philosophy.group.second.description[lang]}>
                        <span>{localData.sections.philosophy.group.second.variations.first[lang]}</span>
                        <span>{localData.sections.philosophy.group.second.variations.second[lang]}</span>
                        <span>{localData.sections.philosophy.group.second.variations.third[lang]}</span>
                        <span>{localData.sections.philosophy.group.second.variations.fourth[lang]}</span>
                        <span>{localData.sections.philosophy.group.second.variations.fifth[lang]}</span>
                        <span>{localData.sections.philosophy.group.second.variations.sixth[lang]}</span>
                    </ServiceBox>
                    <ServiceBox icon={DigitalContentIcon} title={localData.sections.philosophy.group.third.title[lang]} subtitle={localData.sections.philosophy.group.third.description[lang]}>
                        <span>{localData.sections.philosophy.group.third.variations.first[lang]}</span>
                        <span>{localData.sections.philosophy.group.third.variations.second[lang]}</span>
                        <span>{localData.sections.philosophy.group.third.variations.third[lang]}</span>
                        <span>
                            {localData.sections.philosophy.group.third.variations.fourth[lang]}
                        </span>
                        <span>{localData.sections.philosophy.group.third.variations.fifth[lang]}</span>
                        <span>{localData.sections.philosophy.group.third.variations.sixth[lang]}</span>
                    </ServiceBox>
                </ServiceBoxWrapper>
                <br/>
                <ButtonWrapper align="center">
                    <ButtonLink to={localData.sections.philosophy.button.uri[lang]} color="white" background={colors.greenColor} bgHoverColor={colors.grayColor}>{localData.sections.philosophy.button.label[lang]}</ButtonLink>
                </ButtonWrapper>
            </Container>
        </OurServicesSection>
        <SupportSection>
            <Container>
                <SectionTitle align="center">{localData.sections.story.title[lang]}</SectionTitle>
                <span style={{color:colors.grayColor, fontWeight:"bold", display:"block",textAlign: "center"}}>{localData.sections.story.description[lang]}</span>
                <div className="content">
                    <div className="img-block">
                        {/*<img src={BrandProcess} alt=""/>*/}
                        <Img fluid={localizedImages[lang]} />
                    </div>
                </div>
            </Container>
        </SupportSection>
    </Layout>
}

export const query = graphql`
  {
    bprocessEs: file(relativePath: {eq: "brandingprocess_es.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bprocessEn: file(relativePath: {eq: "brandprocess.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`


export default GfBranding
